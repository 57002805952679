<template>
    <div class="container">
        <gadget-step-purchase class="mt-3 mb-3" :step="3" v-if="isStepVisible()"></gadget-step-purchase>

        <div class="support d-sm-block d-md-none">
            <user-regis-benefit></user-regis-benefit>
        </div>

        <div class="box">
            <section class="form pattern-customer">
                <div class="wrapper">
                    <h1>สมัครสมาชิกใหม่</h1>

                    <div class="input-field" v-if="step < 3">
                        <label>หมายเลขโทรศัพท์</label>
                        <div class="input-button">
                            <input type="tel"
                                @keypress="$AppUtility.inputOnlyNumber($event)"
                                maxlength="12"
                                v-model="form.tel"
                                placeholder="ระบุหมายเลขโทรศัพท์"
                                class="square lg" 
                                :class="{ 'invalid': error.tel }"
                                :readonly="countDownOTP > 0 || step > 2" />
                            <button class="btn btn-main square" @click="requestOTP()" v-if="step === 1">ขอ OTP</button>
                            <button class="btn btn-main square" @click="requestOTP()" v-if="step === 2 && countDownOTP === 0">ส่งอีกครั้ง</button>
                        </div>
                        <div class="otp-countdown" v-if="countDownOTP > 0">ลองใหม่อีกครั้งใน <span>{{ helper.displayCountDownOTP(countDownOTP) }}</span></div>
                    </div>

                    <div class="input-field mt-2" v-if="step === 2">
                        <label>SMS ยืนยันตัวตน</label>
                        <input type="tel"
                            @keypress="$AppUtility.inputOnlyNumber($event)"
                            maxlength="6"
                            v-model="form.otp"
                            placeholder="ตัวเลข 6 หลัก"
                            class="square lg" 
                            :class="{ 'invalid': error.otp }" />
                    </div>

                    <template v-if="step === 3">
                        <gadget-policy-input :showUserNote="true" :showPassNote=
                        "true" class="mt-3"></gadget-policy-input>

                        <div class="input-field mt-2">
                            <label>ชื่อผู้ใช้</label>
                            <input type="text"
                                maxlength="50"
                                v-model="form.username"
                                placeholder="สำหรับเข้าสู่ระบบ"
                                class="square lg" 
                                :class="{ 'invalid': error.username }" />
                        </div>
                        <div class="input-field mt-2">
                            <label>ตั้งรหัสผ่าน</label>
                            <div class="input-password">
                                <input :type="isShowPassword ? 'text' : 'password'"
                                    maxlength="50"
                                    v-model="form.password"
                                    placeholder="รหัสผ่าน"
                                    class="square lg" 
                                    :class="{ 'invalid': error.password }" />
                                <button class="show-password" @click="showPassword()"><b-icon :icon="isShowPassword ? 'eye-slash-fill' : 'eye-fill'"></b-icon></button>
                            </div>
                        </div>
                        <div class="input-field mt-2">
                            <label>ยืนยันรหัสผ่าน</label>
                            <div class="input-password">
                                <input :type="isShowConfirmPassword ? 'text' : 'password'"
                                    maxlength="50"
                                    v-model="form.confirmPassword"
                                    placeholder="รหัสผ่าน"
                                    class="square lg" 
                                    :class="{ 'invalid': error.confirmPassword }" />
                                <button class="show-password" @click="showConfirmPassword()"><b-icon :icon="isShowPassword ? 'eye-slash-fill' : 'eye-fill'"></b-icon></button>
                            </div>
                        </div>
                    </template>

                    <div class="term mt-4">
                        <checkbox
                            v-model="form.isAcceptTerm">
                            ข้าพเจ้าได้อ่านและเข้าใจข้อกำหนดและเงื่อนไขในการเปิดเผยข้อมูลส่วนบุคคล และ ยินยอมเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าเพื่อรับบริการกับบริษัทในกลุ่มเยลโลไทร์
                        </checkbox>
                    </div>

                    <div class="mt-4 text-center" v-if="step === 2">
                        <button class="btn btn-main btn-submit" @click="confirmOTP()">ยืนยัน OTP</button>
                    </div>
                    <div class="mt-4 text-center" v-if="step === 3">
                        <button class="btn btn-main btn-submit" @click="registerGeneralUserPassword()">ตั้งรหัสผ่านเพื่อลงทะเบียน</button>
                    </div>

                    <div class="mt-4 text-center or"><span>หรือ</span></div>

                    <div class="mt-4">
                        <button class="btn btn-brand btn-facebook" @click="registerByFacebook()">
                            <font-awesome-icon :icon="['fab', 'facebook']" class="icon" />
                            ลงทะเบียนด้วย facebook
                        </button>
                    </div>
                    <div class="mt-1">
                        <button class="btn btn-brand btn-google" @click="registerByGoogle()">
                            <font-awesome-icon :icon="['fab', 'google']" class="icon" />
                            ลงทะเบียนด้วย Gmail
                        </button>
                    </div>
                </div>

                <div class="term mt-4">
                    ข้าพเจ้าตกลงตาม <router-link :to="routerPath.STATIC_TERM" target="_blank" title="ข้อกำหนดการใช้งาน">ข้อกำหนดการใช้งาน</router-link> และ <router-link :to="routerPath.STATIC_PRIVACY" target="_blank" title="นโยบายความเป็นส่วนตัว">นโยบายความเป็นส่วนตัว</router-link>
                </div>
            </section>

            <section class="link pattern-customer">
                <div class="wrapper">
                    <h2>มีบัญชีอยู่แล้ว?</h2>
                    <p>หากท่านเป็นลูกค้าที่เป็นสมาชิกกับเราอยู่แล้ว กดลิงค์ด้านล่างสำหรับเปิดหน้าลงชื่อเข้าใช้งาน</p>

                    <div class="action">
                        <router-link class="btn btn-warning" :to="routerPath.LOGIN">ลงชื่อเข้าใช้</router-link>
                    </div>

                    <div class="support d-none d-md-block">
                        <user-regis-benefit textColor="#ccc"></user-regis-benefit>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import RouterPath from '@/router/path';
import Helper from '@/services/helper';
import UserService from '@/services/userService';
import GoogleAuth from '@/services/googleAuth';
import GadgetPolicyInput from '@/components/gadget/PolicyInput';
import UserRegisBenefit from '@/components/user/RegisBenefit';
import GadgetStepPurchase from '@/components/gadget/StepPurchase';

export default {
    components: { GadgetPolicyInput, UserRegisBenefit, GadgetStepPurchase },
	data() {
        return {
            helper: Helper,
            routerPath: RouterPath,
			isShowPassword: false,
            isShowConfirmPassword: false,
            countDownOTP: 0,
            form: this.initForm(),
            error: this.initError(),
            step: 1,
            otpToken: ''
        }
    },
    methods: {
        initForm() {
            return {
                tel: '',
                otp: '',
                hashTel: '',
                username: '',
                password: '',
                confirmPassword: '',
                isAcceptTerm: false
            };
        },
        initError() {
            return {
                tel: false,
                otp: false,
                username: false,
                password: false,
                confirmPassword: false
            };
        },
        countDownTimer() {
            if (this.countDownOTP > 0) {
                setTimeout(() => {
                    this.countDownOTP -= 1
                    this.countDownTimer()
                }, 1000);
            }
        },
        async requestOTP() {
            if (this.validateTel()) {
                let loader = this.$modalLoader.render();

                const recaptcha = await this.getRecaptchaToken();
                const result = await UserService.requestOTP(this.form.tel, recaptcha);

                loader.hide();

                if (result.data?.success) {
                    this.countDownOTP = 59;
                    this.countDownTimer();
                    this.otpToken = result.data.token;
                    this.step = 2;
                } else {
                    this.$swal(Helper.warningAlert('ขอ OTP ไม่สำเร็จ', result.data?.message));
                }
            }
        },
        async getRecaptchaToken() {
            await this.$recaptchaLoaded()

            const recaptcha = await this.$recaptcha('customerRegister');

            return recaptcha;
        },
        validateTel() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;

            // Check Blank
            if (!this.form.tel?.trim()) {
                this.error.tel = true;
                isValid = false;
                isBlank = true;
            }

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('ขอ OTP ไม่สำเร็จ', 'โปรดกรอกเบอร์โทรศัพท์ของท่าน'));
            }

            return isValid;
        },
        async confirmOTP() {
            if (this.validateOTP()) {
                let loader = this.$modalLoader.render();
                const result = await UserService.submitOTP('register', this.form.tel, this.form.otp, this.otpToken);
                loader.hide();

                if (result.data?.success) {
                    this.form.hashTel = result.data.key;
                    this.step = 3;

                    // Hide countdown
                    this.countDownOTP = 0;
                } else {
                    this.$swal(Helper.warningAlert('ยืนยัน OTP ไม่สำเร็จ', result.data?.message));
                }
            }
        },
        validateOTP() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;

            // Check Blank
            if (!this.form.otp?.trim()) {
                this.error.otp = true;
                isValid = false;
                isBlank = true;
            }

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('ลงทะเบียนไม่สำเร็จ', 'โปรดกรอกหมายเลข OTP'));
            }

            return isValid;
        },
        async registerGeneralUserPassword() {
            if (this.validatePassword()) {
                let loader = this.$modalLoader.render();
                const result = await UserService.registerGeneralUserPassword(this.form.hashTel, this.form.username, this.form.password);
                loader.hide();

                this.afterAuthorizeUser(result);
            }
        },
        validatePassword() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;
            let isNotMatchPassword = false;
            let isInvalidUsername = false;
            let isInvalidPassword = false;
            let isInvalidTerm = false;

            // Check Blank
            if (!this.form.username?.trim()) {
                this.error.username = true;
                isValid = false;
                isBlank = true;
            }

            if (!this.form.password?.trim()) {
                this.error.password = true;
                isValid = false;
                isBlank = true;
            }

            if (!this.form.confirmPassword?.trim()) {
                this.error.confirmPassword = true;
                isValid = false;
                isBlank = true;
            }

            // Compare Password
            if (this.form.password !== this.form.confirmPassword) {
                this.error.confirmPassword = true;
                isValid = false;
                isNotMatchPassword = true;
            }

            // Check Username Format
            if (!Helper.validateUsernameFormat(this.form.username)) {
                this.error.username = true;
                isValid = false;
                isInvalidUsername = true;
            }

            // Check Password Format
            const passwordPolicy = Helper.validatePasswordFormat(this.form.password);
            
            if (!passwordPolicy.isValid) {
                this.error.password = true;
                isValid = false;
                isInvalidPassword = true;
            }

            // No Accept Term
            if (!this.form.isAcceptTerm) {
                isValid = false;
                isInvalidTerm = true;
            }

            // Popup
            const title = 'ตั้งชื่อผู้ใช้และรหัสผ่านไม่สำเร็จ';

            if (isBlank) {
                this.$swal(Helper.warningAlert(title, 'โปรดกรอกรหัสผ่านและยืนยืนให้ถูกต้อง'));
            } else if (isNotMatchPassword) {
                this.$swal(Helper.warningAlert(title, 'ยืนยันรหัสผ่านไม่ถูกต้อง'));
            } else if (isInvalidUsername) {
                this.$swal(Helper.warningAlert(title, 'โปรดตรวจสอบรูปแบบชื่อผู้ใช้ เนื่องจากยังไม่ครบตามเงื่อนไข'));
            } else if (isInvalidPassword) {
                this.$swal(Helper.warningAlert('รหัสผ่านไม่ตรงตามเงื่อนไขด้านล่าง', '', Helper.getWarningPolicyMessage(passwordPolicy.message)));
            } else if (isInvalidTerm) {
                this.$swal(Helper.warningAlert('ยังไม่ได้ยินยอมข้อกำหนดและเงื่อนไข', 'โปรดอ่านข้อกำหนดและเงื่อนไขในการเปิดเผยข้อมูลส่วนบุคคล และยินยอมเปิดเผยข้อมูลส่วนบุคคลเพื่อรับบริการกับบริษัทในกลุ่มเยลโลไทร์'));
            }

            return isValid;
        },
        showPassword() {
            this.isShowPassword = !this.isShowPassword;
        },
        showConfirmPassword() {
            this.isShowConfirmPassword = !this.isShowConfirmPassword;
        },
        async registerByFacebook() {
            window.FB.login((authResult) => {
                if (authResult.authResponse) {
                    window.FB.api('/me', async (fbResult) => {
                        let loader = this.$modalLoader.render();
                        const result = await UserService.submitLoginFacebook(fbResult, authResult.authResponse);
                        loader.hide();

                        this.afterAuthorizeUser(result);
                    });
                }
            });
        },
        async registerByGoogle() {
            const result = await GoogleAuth.auth();

            if (result) {
                let loader = this.$modalLoader.render();
                const result = await UserService.submitLoginGoogle(result.access_token);
                loader.hide();

                this.afterAuthorizeUser(result);
            }
        },
        afterAuthorizeUser(result) {
            if (result.data?.success) {
                UserService.setUserToken(result.data.token);
                UserService.setUsername(result.data.username);

                this.$root.$emit('rootSetUserDisplay');

                if (localStorage.cartData) {
                    this.$router.push(RouterPath.CART);
                } else {
                    this.$router.push('/');
                }
            } else {
                this.$swal(Helper.warningAlert('ลงทะเบียนไม่สำเร็จ', result.data?.message));
            }
        },
        isStepVisible() {
            if (localStorage.cartData) {
                return true;
            }

            return false;
        }
    },
    metaInfo() {
		const title = 'ลงทะเบียนสมาชิก | YELLOWTiRE';
		const desc = 'ลงทะเบียนเป็นสมาชิกกับ YELLOWTiRE';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
                { vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
                { vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/user/style/Authentication.scss';
</style>